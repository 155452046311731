import React from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import DefaultHero from "../components/common/default-hero"
import { graphql } from "gatsby"
import ArticlesSection from "../components/common/articles-section"
import FullWidthView from "../components/common/full-width-view"

export const query = graphql`
  {
    prismic {
      allMemberships {
        edges {
          node {
            hero_background_image
            hero_background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            hero_title
            hero_information
            articles {
              title
              information
            }
            articles_image
            articles_imageSharp {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            divider_image
            divider_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
const BliMedlem = ({ data }) => {
  const membership = data.prismic.allMemberships.edges[0].node
  return (
    <Layout currentSiteUrl="/bli-medlem">
      <SEO title='Bli medlem' />
      <DefaultHero heroImg={membership.hero_background_imageSharp.childImageSharp.fluid} heroTitle={membership.hero_title} heroInformation={membership.hero_information} />
      <ArticlesSection articles={membership.articles} fluidImg={membership.articles_imageSharp.childImageSharp.fluid} />
      <FullWidthView marginBottom='10rem' fluidImg={membership.divider_imageSharp.childImageSharp.fluid} />
    </Layout>
  )
}
export default BliMedlem
